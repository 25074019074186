import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import HeaderCase from 'blocks/case/headerCase'
import SectionHalfIntroduction from 'blocks/case/sectionHalfIntroduction'
import SectionHeadingFullWidth from 'blocks/case/sectionHeadingFullWidth'
import Dinamiza from 'components/colorDinamiza'
import SectionTextImageContainer from 'blocks/case/sectionTextImageContainer'
import ParagraphLine from 'components/paragraphLine'
import Image from 'components/image'
import style from 'scss/pages/projects/vega.module.scss'
import SectionDark from 'components/sectionDark'
import SectionTextImageFullWidth from 'blocks/case/sectionTextImageFullWidth'
import SectionSlider from 'blocks/case/sectionSlider'
import FooterCase from 'blocks/case/footerCase'
import VideoIllustration from "blocks/case/videoIllustration";
import behavAnimation from "images/projects/navigando/behav-navigando.json"

class Navigando extends React.Component {

  render() {

    const { location, data } = this.props

    const imagePath = '/img/projects/navigando/'

    const headerInfo = {
      data: "22.08.2019",
      color: "#fff",
      logoUrl: imagePath+'logo_navigando.svg',
      image: imagePath+'bg_navigando.jpg',
      title: "Un progetto di visibilità e lead generation",
      tags: ["ANALISI DATI","LEAD GENERATION","SEO - SEM","PROJECT MANAGEMENT","FORMAZIONE","CRO OPTIMIZATION"]
    }

    const slideImages = ['/img/screenshot/nav1.jpg','/img/screenshot/nav2.jpg','/img/screenshot/nav3.jpg']

    return(
      <div>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Navigando" description={'Affidaci la strategia Digitale della tua Azienda, creaimo campagne di Lead generation, DEM, landing pages, Campagne social. Trova clienti con il web.'}/>

          <HeaderCase data={headerInfo} />

          <SectionHalfIntroduction>
            <h2>Un progetto di visibilità e lead generation</h2>
            <p>Navigando è una realtà intraprendente che ricerca soluzioni personalizzate per proporre nuovi modi di viaggiare e di imparare le lingue all'estero.<br/><br/>
              L'esigenza era quella di avere una regia che potesse gestire al meglio le attività di digital marketing, con un occhio alla strategia generale del brand. </p>
          </SectionHalfIntroduction>

          <SectionTextImageContainer>
            <ParagraphLine>
              <h2 data-title>Users Behavioural monitoring</h2>
              <p>In ottica di un restyling e ottimizzazione del sito, è stato installato un tool di monitoraggio del comportamento degli che utenti che visitano il sito.<br/><br/>Registrando abitudini d'uso, comportamenti anomali, pagine più visitate e come l'utente si muove all'interno di una singola pagina, è possibile fare previsioni dei bisogni latenti, lavorando sui contenuti e sulle interazioni in modo da migliorare l'esperienza utente e finalizzarla in una richiesta di contatto.</p>
            </ParagraphLine>
            <div>
              <VideoIllustration src={behavAnimation} style={{ width:'100%' }} className={style.page__firstImage} />
            </div>
          </SectionTextImageContainer>

          <SectionTextImageContainer textPosition={'right'}>
            <ParagraphLine>
              <h2 data-title>Social Media Optimization</h2>
              <p>Attraverso un corso di formazione avanzato e supporto continuativo abbiamo guidato il team interno Navigando nell'ottimizzazione dei contenuti visivi, testuali per raggiungere gli obiettivi condivisi di awareness e lead generation.</p>
            </ParagraphLine>
            <div>
              <img src={imagePath+'adv_navigando.png'} className={style.page__firstImage} />
            </div>
          </SectionTextImageContainer>

          <SectionDark imageSrc={imagePath+'bg_navigando.jpg'} imagePosition={'top'}>

            <SectionTextImageFullWidth textPosition={'right'} padding={false} imageFade={false}>
              <img src={imagePath+'keywords_navigando.svg'} style={{ maxHeight: '500px' }} />
              <ParagraphLine>
                <h2 data-title className={`has-color-primary-negative`}>SEO & SEA Strategy</h2>
                <p className={`has-color-primary-negative`}>Per supportare l'obiettivo di Navigando di generare contatti portando traffico anche al sito, si è lavorato sull’ottimizzazione SEO e parallelamente su ADS con differenti creatività in base al tipo di prodotto e target.</p>
              </ParagraphLine>
            </SectionTextImageFullWidth>

            <SectionTextImageFullWidth textPosition={'left'} padding={false} imageFade={false}>
              <div className={style.page__secondImage} style={{ marginRight: 'auto' }}>
                <img src={imagePath+'campagne_navigando.png'} />
              </div>
              <ParagraphLine>
                <h2 data-title className={`has-color-primary-negative`}>Creative Campaign</h2>
                <p className={`has-color-primary-negative`}>In ottica di ottimizzare i risultati delle campagne sono stati ideati vari formati, per i diversi target, con visual ottimizzati a seguito di A/B test. Abbiamo utilizzato le campagne che han riscosso maggior successo come best practices per le successive, incrementando via via i risultati.</p>
              </ParagraphLine>
            </SectionTextImageFullWidth>

            <SectionTextImageFullWidth textPosition={'right'} padding={false} imageFade={false}>
              <img src={imagePath+'risultati_navigando.svg'} style={{ maxHeight: '500px' }} />
              <ParagraphLine>
                <h2 data-title className={`has-color-primary-negative`}>SEO & SEA Strategy</h2>
                <p className={`has-color-primary-negative`}>Per supportare l'obiettivo di Navigando di generare contatti portando traffico anche al sito, si è lavorato sull’ottimizzazione SEO e parallelamente su ADS con differenti creatività in base al tipo di prodotto e target.</p>
              </ParagraphLine>
            </SectionTextImageFullWidth>

          </SectionDark>

          <FooterCase prevProject={'petra'} nextProject={'materica'} nextProjectColor={'#000000'} />

        </Layout>
      </div>
    )

  }
}

export default Navigando
